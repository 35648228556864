import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import CenteredLayout from '../layouts/CenteredLayout'

export default function NotAuthorized() {
  return (
    <CenteredLayout>
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={8}
        xl={6}
      >
        <h1 className="text-center mb-4">
          Not authorized
        </h1>

        <p className="text-center text-muted mb-5">
          You are not authorized to view this content or perform this action.<br />
          Please go back to our homepage to find the information you wanted to access.
        </p>

        <p className="text-center mb-0">
          <Link
            to="/"
            className="btn btn-primary w-50"
          >
            Back to Home
          </Link>
        </p>
      </Col>
    </CenteredLayout>
  )
}
