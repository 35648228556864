import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ArrowLeft, RefreshCw } from 'react-feather'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { DetailData } from '../../netlify/functions/detail/detail'
import DetailCard from '../components/DetailCard'
import Topnav from '../components/Topnav'
import Climate from '../components/detail/Climate'
import Economics from '../components/detail/Economics'
import General from '../components/detail/General'
import LandUse from '../components/detail/LandUse'
import Map from '../components/detail/Map'
import Soil from '../components/detail/Soil'
import Topography from '../components/detail/Topography'
import api from '../utils/api'

import Loading from './aux/Loading'

export default function Detail() {
  const abortController = new AbortController()

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const ids = searchParams.get('ids')

  const [data, setData] = useState<DetailData|null>(null)
  const [error, setError] = useState<{ type: string, message: string }|null>(null)

  const mapRef = useRef<google.maps.Map|null>(null)

  const loadData = () => {
    setError(null)

    api.get(`/.netlify/functions/detail?ids=${ids}`)
      .then(response => {
        setData(response.data)
        setError(null)
      })
      .catch(err => {
        setError({
          type: err.response.data.errorType || '', 
          message: err.response.data.errorMessage || '' 
        })
      })
  }

  useEffect(() => {
    loadData()

    return () => abortController.abort()
  }, [])

  if (error !== null) {
    return (
      <div 
        className="d-flex align-items-center justify-content-center"
        style={{
          position: 'absolute',
          top: '0px',
          bottom: '0px',
          left: '0px',
          right: '0px',
          background: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        <div
          className="bg-white p-4 rounded-3"
          style={{ maxWidth: '450px' }}
        >
          <p className="text-center">
            { error.type === 'SeeMessage' ? (
              <span>{ error.message }</span>
            ) : (
              <span>An unknown error has occurred. Please try again.</span>
            ) }
          </p>

          <p className="text-center mb-0">
            <button
              type="button"
              className="btn btn-primary d-flex align-items-center mx-auto"
              onClick={loadData}
            >
              <RefreshCw
                size="14px"
                className="me-3"
              />
              Load again
            </button>
          </p>
        </div>
      </div>
    )
  }

  if (data === null) {
    return <Loading absolute={true} />
  }

  return (
    <>
      <Topnav>
        <Link
          to="/"
          className="btn btn-sm btn-outline-primary d-flex align-items-center"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft
            size="14px"
            className="me-2"
          />
          Back to map
        </Link>
      </Topnav>

      <div 
        className="position-relative"
        style={{ width: '100%', height: 'calc(100vh - 65px)', marginTop: '65px' }}
      >
        <Row className="h-100 mx-0">
          <Col
            xs={12}
            md={6}
            className="h-100 p-3"
            style={{ overflowY: 'scroll' }}
          >
            <General data={data} />

            <Topography
              data={data}
              mapRef={mapRef}
            />

            <Climate data={data} />
            
            <LandUse
              data={data}
              mapRef={mapRef}
            />

            <Economics data={data} />

            <Soil data={data} />
          </Col>

          <Col 
            className="px-0"
          >
            <Map
              data={data}
              mapRef={mapRef}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}
