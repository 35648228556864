import { Form, Formik } from 'formik'
import { MutableRefObject, useState } from 'react'
import { Col, Dropdown, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import api from '../../utils/api'
import LoadingButton from '../LoadingButton'
import Select from '../fields/Select'

export default function SearchByLandIdentifier({ mapRef }: { mapRef: MutableRefObject<google.maps.Map|null> }) {
  const [show, setShow] = useState<boolean>(false)

  const navigate = useNavigate()

  const scopes = ['provincia', 'municipio', 'agregado', 'zona', 'poligono', 'parcela'] as ('provincia'|'municipio'|'agregado'|'zona'|'poligono'|'parcela')[]

  const [locationOptions, setLocationOptions] = useState<{[key: string]: {label: string, value: string|number}[]}>({
    provincias: [],
    municipios: [],
    agregados: [],
    zonas: [],
    poligonos: [],
    parcelas: [],
  })

  const loadLocationOptions = async (idx: number, values: {[key: string]: any}) => {
    const scope = scopes[idx]
  
    const previousScopes = scopes.slice(0, idx)
    const path = idx === 0
      ? values.comunidad
      : previousScopes.map(scope => values[scope]).join('/')

    setLocationOptions({ ...locationOptions, [`${scope}s`]: [] })

    const response = await api.get(`.netlify/functions/id_options?path=${scope}s/${path}`)

    setLocationOptions({ ...locationOptions, [`${scope}s`]: [
      { label: 'Choose an option...', value: '' },
      ...response.data,
    ]})
  }

  return (
    <>
      <Dropdown.Item 
        onClick={() => setShow(true)}
      >
        Search by land identifier
      </Dropdown.Item>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Search by land identifier
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Formik
            initialValues={{
              country: 'ES',
              comunidad: '',
              provincia: '',
              municipio: '',
              agregado: '',
              zona: '',
              poligono: '',
              parcela: '',
            }}
            onSubmit={async (values, actions) => {
              navigate(`/detail?ids=${Object.values(values).splice(2).join(':')}`)
            }}
          >
            { ({ values, isSubmitting, setFieldValue }) => (
              <Form>
                <Row className="mb-3 d-flex align-items-center">
                  <Col
                    xs={12}
                    md={3}
                    className="text-muted"
                  >
                    Country
                  </Col>

                  <Col
                    xs={12}
                    md={9}
                  >
                    <Select
                      name="country"
                      label="Country"
                      options={[
                        { label: 'Spain', value: 'ES' }
                      ]}
                    />
                  </Col>
                </Row>

                <>
                  <Row className="mb-3 d-flex align-items-center">
                    <Col
                      xs={12}
                      md={3}
                      className="text-muted"
                    >
                      Comunidad
                    </Col>

                    <Col
                      xs={12}
                      md={9}
                    >
                      <Select
                        name="comunidad"
                        label="Comunidad"
                        options={[
                          { label: 'ANDALUCIA (1)', value: 1 },
                          { label: 'ARAGÓN (2)', value: 2 },
                          { label: 'ASTURIAS (3)', value: 3 },
                          { label: 'C. VALENCIANA (17)', value: 17 },
                          { label: 'CANARIAS (5)', value: 5 },
                          { label: 'CANTABRIA (6)', value: 6 },
                          { label: 'CASTILLA-LA MANCHA (7)', value: 7 },
                          { label: 'CASTILLA Y LEÓN (8)', value: 8 },
                          { label: 'CATALUÑA (9)', value: 9 },
                          { label: 'EXTREMADURA (10)', value: 10 },
                          { label: 'GALICIA (11)', value: 11 },
                          { label: 'ILLES BALEARS (4)', value: 4 },
                          { label: 'LA RIOJA (16)', value: 16 },
                          { label: 'MADRID (12)', value: 12 },
                          { label: 'MURCIA (13)', value: 13 },
                          { label: 'NAVARRA (14)', value: 14 },
                          { label: 'PAIS VASCO (15)', value: 15 },
                        ]}
                        onChange={(option: any) => {
                          setFieldValue('comunidad', option.value)
                          setFieldValue('provincia', '')
                          setFieldValue('municipio', '')
                          setFieldValue('agregado', '')
                          setFieldValue('zona', '')
                          setFieldValue('poligono', '')
                          setFieldValue('parcela', '')

                          if (option.value !== '') {
                            loadLocationOptions(0, {...values, comunidad: option.value})
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </>

                { scopes.map((scope, idx) => (
                  <>
                    {/* { (idx === 0 ? values.comunidad : values[scopes[idx-1]]) !== '' && ( */}

                    <Row
                      className="mb-3 d-flex align-items-center"
                      key={idx}
                    >
                      <Col
                        xs={12}
                        md={3}
                        className="text-muted"
                      >
                        { scope.charAt(0).toUpperCase() + scope.substring(1) }
                      </Col>

                      <Col
                        xs={12}
                        md={9}
                      >
                        <Select
                          name={scope}
                          label={scope.charAt(0).toUpperCase() + scope.substring(1)}
                          options={locationOptions[`${scope}s`]}
                          isDisabled={locationOptions[`${scope}s`].length === 0}
                          onChange={(option: any) => {
                            setFieldValue(scope, option.value)

                            scopes.forEach((nextScope, nextScopeIdx) => {
                              if (nextScopeIdx > idx) {
                                setFieldValue(nextScope, '')
                              }
                            })

                            if (idx < scopes.length - 1 && option.value !== '') {
                              loadLocationOptions(idx + 1, {...values, [scope]: option.value})
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    {/* ) } */}
                  </>
                )) }

                <div className="d-flex justify-content-end">
                  <LoadingButton
                    busy={isSubmitting}
                    type="submit"
                    className="btn btn-primary px-5 mt-2 mb-0"
                    disabled={isSubmitting || (Object.values(values).filter(val => val === '').length > 0) }
                  >
                    View selected land
                  </LoadingButton>
                </div>
              </Form>
            ) }
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
