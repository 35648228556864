import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import CenteredLayout from '../layouts/CenteredLayout'

export default function NotFound() {
  return (
    <CenteredLayout>
      <Col
        xs={12}
        sm={12}
        md={10}
        lg={8}
        xl={6}
      >
        <h1 className="text-center mb-4">
        Page not found
        </h1>

        <p className="text-center text-muted mb-5">
        The page you are looking for has been moved or deleted and no longer exists.<br />
        Please go back to our homepage to find the information you wanted to access.
        </p>

        <p className="text-center mb-0">
          <Link
            to="/"
            className="btn btn-primary w-50"
          >
          Back to Home
          </Link>
        </p>
      </Col>
    </CenteredLayout>
  )
}
