import classNames from 'classnames'
import { MutableRefObject, useState } from 'react'

import { DetailData } from '../../../netlify/functions/detail/detail'
import DetailCard from '../DetailCard'

enum Status {
  None,
}

export default function LandUse({ data, mapRef }: { data: DetailData, mapRef: MutableRefObject<google.maps.Map|null> }) {
  const [status, setStatus] = useState<Status>(Status.None)

  return (
    <>
      <DetailCard
        title="Land use distribution"
      >
        { data.uses.map((use, index) => (
          <div 
            key={index}
            className={classNames(
              'pb-2',
              index > 0 && 'pt-3',
            )}
            style={{ cursor: 'help' }}
            onMouseOver={() => {
              const map = mapRef.current

              if (map !== null) {
                map.data.setStyle(feature => ({
                  strokeColor: 'white',
                  strokeOpacity: feature.getProperty('use').name === use.name ? 1.0 : 0.0,
                  fillColor: feature.getProperty('use').name === use.name ? use.color : 'white',
                  fillOpacity: feature.getProperty('use').name === use.name ? 0.5 : 0.0,
                }))
              }
            }}
            onMouseOut={() => {
              const map = mapRef.current

              if (map !== null) {
                map.data.setStyle(feature => ({
                  fillColor: 'white',
                  fillOpacity: 0.0,
                  strokeColor: 'white',
                  strokeOpacity: 1.0,
                }))
              }
            }}
          >
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span>{ use.name }</span>

              <span>{ use.area.toFixed(2) } ha ({ ((use.area / data.topography.area) * 100).toFixed(2) }%)</span>
            </div>

            <div
              className="w-100 bg-lighter rounded-2 border"
              style={{ height: '15px' }}
            >
              <div
                className="rounded-2 rounded-end-0 h-100"
                style={{ 
                  width: `${((use.area / data.topography.area) * 100)}%`,
                  backgroundColor: use.color,
                }}
              />
            </div>
          </div>
        )) }
      </DetailCard>
    </>
  )
}
