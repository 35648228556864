import currencySymbol from './currencySymbol'

export default function currencyFormat(amount: number, currency = 'EUR', forceDecimals = false) {
  const amountString = forceDecimals
    ? amount.toFixed(2)
    : amount.toString()

  const symbol = currencySymbol(currency)

  if (!amountString.includes('.')) {
    const number = amountString.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    
    return `${symbol}${number}`
  }

  const preSeparator = amountString.substring(0, amountString.indexOf('.'))
  const postSeparator = amountString.substring(amountString.indexOf('.')+1)

  const number = preSeparator.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + postSeparator
  
  return `${symbol}${number}`
}
