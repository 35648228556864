import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import api from './utils/api'
import { useAppSelector, useAppDispatch } from './utils/hooks'
import { fetchMeta, selectUser } from './utils/store/meta'
import { selectStatus, setStatusCode } from './utils/store/status'
import Detail from './views/Detail'
import Home from './views/Home'
import Loading from './views/aux/Loading'
import NotAdmin from './views/errors/NotAdmin'
import NotAuthorized from './views/errors/NotAuthorized'
import NotFound from './views/errors/NotFound'

export default function App() {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        })
          .then(accessToken => {
            api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
            dispatch(fetchMeta())
          })
      } else {
        loginWithRedirect()
      }
    }
  }, [isLoading, isAuthenticated])

  useEffect(() => {
    dispatch(setStatusCode(200))
  }, [location.key])

  const user = useAppSelector(selectUser)
  const status = useAppSelector(selectStatus)

  if (status.code === 401) {
    return (
      <NotAdmin />
    )
  }

  if (isLoading || user === null || status.code === -1) {
    return (
      <Loading absolute={true} />
    )
  }

  if (status.code === 403) {
    return (
      <NotAuthorized />
    )
  }

  if (status.code === 404) {
    return (
      <NotFound />
    )
  }

  return (
    <Routes>
      <Route
        path=""
        element={<Home />}
      />

      <Route
        path="detail"
        element={<Detail />}
      />

      <Route
        path="*"
        element={<NotFound />}
      />
    </Routes>
  )
}
