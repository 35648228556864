import { Spinner } from 'react-bootstrap'

export default function Loading({ absolute = false }: { absolute?: boolean }) {
  return (
    <div
      style={{
        ...( 
          absolute 
            ? {
              position: 'absolute',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
            }
            : {
              flex: 'auto',
            }
        ),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 'auto',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Spinner
          animation="border"
          style={{ width: '80px', height: '80px', opacity: 0.75 }}
        />

        <img
          src="/images/sign.png"
          srcSet="/images/sign@2x.png 2x, /images/sign@3x.png 3x"
          alt="Loading..."
          style={{
            position: 'absolute',
            height: '40px',
            marginTop: '20px',
          }}
        />
      </div>
    </div>
  )
}
