import axios from 'axios'
import GoogleMapReact from 'google-map-react'
import { useEffect, useRef, useState } from 'react'
import { Col, Container, Dropdown, DropdownButton, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import { FileText, Grid, RefreshCw, Search, Star, X } from 'react-feather'
import { useNavigate, useSearchParams } from 'react-router-dom'

import LoadingButton from '../components/LoadingButton'
import Topnav from '../components/Topnav'
import SearchByLandIdentifier from '../components/home/SearchByLandIdentifier'
import api from '../utils/api'

export default function Home() {
  const abortController = useRef<AbortController>(new AbortController())

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [busy, setBusy] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  const [lat, setLat] = useState<number>(parseFloat(searchParams.get('lat') ?? '40.7106169'))
  const [lng, setLng] = useState<number>(parseFloat(searchParams.get('lng') ?? '-4.2134955'))
  const [zoom, setZoom] = useState<number>(parseFloat(searchParams.get('zoom') ?? '6'))

  const [selectedLands, _setSelectedLands] = useState<string[]>([])
  const selectedLandsRef = useRef<string[]>(selectedLands)
  const setSelectedLands = (lands: string[]) => {
    selectedLandsRef.current = lands
    _setSelectedLands(lands)
  }

  const mapRef = useRef<google.maps.Map|null>(null)

  const defaultLocation = {
    center: {
      lat: 40.7106169,
      lng: -4.2134955
    },
    zoom: 6
  }

  const mapFeatureId = (feature: google.maps.Data.Feature) => {
    return `${feature.getProperty('provincia')}:${feature.getProperty('municipio')}:${feature.getProperty('agregado')}:${feature.getProperty('zona')}:${feature.getProperty('poligono')}:${feature.getProperty('parcela')}`
  }

  const isMapFeatureSelected = (feature: google.maps.Data.Feature) => {
    return selectedLandsRef.current.includes(mapFeatureId(feature))
  }

  const loadPlots = async (givenBounds?: google.maps.LatLngBounds) => {
    const map = mapRef.current

    if (map === null) {
      return
    }

    if (!givenBounds) {
      abortController.current.abort()
      abortController.current = new AbortController()
    }

    const bounds = givenBounds || map.getBounds()
    const zoom = map.getZoom()

    if (bounds === undefined || zoom === undefined || zoom < 15) {
      return
    }

    const ne4326 = `${bounds.getNorthEast().lng()},${bounds.getNorthEast().lat()}`
    const sw4326 = `${bounds.getSouthWest().lng()},${bounds.getSouthWest().lat()}`

    setBusy(true)
    // map.data.forEach(feature => { map.data.remove(feature) })

    try {
      const response = await api.get(`/.netlify/functions/map?ne=${ne4326}&sw=${sw4326}`, { signal: abortController.current.signal })

      if (response !== undefined) {
        map.data.addGeoJson(response.data)

        if (!givenBounds) {
          setBusy(false)
        }
        setError(false)
      }
    }
    catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 502 && err.response?.data.errorType === 'Function.ResponseSizeTooLarge') {
        const lng1 = bounds.getNorthEast().lng()
        const lng2 = (bounds.getNorthEast().lng() + bounds.getSouthWest().lng()) / 2
        const lng3 = bounds.getSouthWest().lng()

        const lat1 = bounds.getNorthEast().lat()
        const lat2 = (bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2
        const lat3 = bounds.getSouthWest().lat()

        const bounds1 = new google.maps.LatLngBounds({ lat: lat2, lng: lng2 }, { lat: lat1, lng: lng1 })
        const bounds2 = new google.maps.LatLngBounds({ lat: lat2, lng: lng3 }, { lat: lat1, lng: lng2 })
        const bounds3 = new google.maps.LatLngBounds({ lat: lat3, lng: lng2 }, { lat: lat2, lng: lng1 })
        const bounds4 = new google.maps.LatLngBounds({ lat: lat3, lng: lng3 }, { lat: lat2, lng: lng2 })

        await Promise.all([
          loadPlots(bounds1),
          loadPlots(bounds2),
          loadPlots(bounds3),
          loadPlots(bounds4)
        ])

        if (!givenBounds) {
          setBusy(false)
        }
      } else {
        setBusy(false)
        setError(true)
      }
    }
  }

  const resetMap = () => {
    const searchInput = document.getElementById('search') as HTMLInputElement

    searchInput.value = ''

    mapRef.current?.setCenter(defaultLocation.center)
    mapRef.current?.setZoom(defaultLocation.zoom)

    setSearchParams({})
  }

  useEffect(() => {
    return () => abortController.current.abort()
  }, [])

  useEffect(() => {
    if (lat === defaultLocation.center.lat && lng === defaultLocation.center.lng && zoom === defaultLocation.zoom) {
      return
    }

    setSearchParams({
      lat: lat.toString(),
      lng: lng.toString(),
      zoom: zoom.toString(),
    }, { replace: true })
  }, [lat, lng, zoom])

  return (
    <>
      <Topnav>
        <>
          <InputGroup style={{ width: '380px' }}>
            <Dropdown>
              <Dropdown.Toggle
                role="button"
                variant="white"
                className="d-flex flex-row align-items-center px-3"
              >
                <Search
                  size="16px"
                  className="flex-shrink-0"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <SearchByLandIdentifier mapRef={mapRef} />
              </Dropdown.Menu>
            </Dropdown>
            
            <input
              id="search"
              type="text"
              className="form-control"
              placeholder="Search a location..."
              style={{
                zIndex: '1050'
              }}
            />

            <InputGroup.Text 
              className="cursor-pointer text-muted fs-5"
              onClick={resetMap}
            >
              {/* <X
                size="16px"
                className="cursor-pointer text-muted flex-shrink-0"
                onClick={resetMap}
              /> */}
              Reset
            </InputGroup.Text>
          </InputGroup>

          <div className="flex-auto" />

          <div className="d-flex">
            <OverlayTrigger
              placement="bottom"
              overlay={ 
                selectedLands.length === 0 ? (
                  <Tooltip>
                    Select at least one land plot to run a report.
                  </Tooltip>
                ) : <></>
              }
            >
              <div>
                <button
                  className="btn btn-primary d-flex align-items-center"
                  onClick={() => {
                    if (selectedLands.length > 0) {
                      navigate(`/detail?ids=${selectedLands.join(',')}`)
                    }
                  }}
                  disabled={selectedLands.length === 0}
                >
                  <FileText
                    size="14px"
                    className="me-2"
                  />

                  Run report ({ selectedLands.length })
                </button>
              </div>
            </OverlayTrigger>

            <button
              className="btn btn-outline-primary ms-2"
              onClick={() => {
                setSelectedLands([])

                if (mapRef.current) {
                  mapRef.current.data.forEach(feature => {
                    if (mapRef.current) {
                      mapRef.current.data.overrideStyle(feature, {
                        fillOpacity: 0.0,
                      })
                    }
                  })
                }
              }}
              disabled={selectedLands.length === 0}
            >
              Clear
            </button>

            {/* <button
              type="button"
              className="btn btn-outline-primary d-flex align-items-center ms-2"
            >
              <Star size="16px" />
            </button> */}
          </div>
        </>
      </Topnav>

      <div 
        className="position-relative"
        style={{
          width: '100%',
          height: 'calc(100vh - 65px)',
          marginTop: '65px',
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ 
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
            libraries: ['places']
          }}
          defaultCenter={defaultLocation.center}
          defaultZoom={defaultLocation.zoom}
          options={{ mapTypeId: 'hybrid' }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }: { map: google.maps.Map }) => {
            mapRef.current = map

            const lat = searchParams.get('lat')
            const lng = searchParams.get('lng')

            if (lat !== null && !isNaN(parseFloat(lat)) && lng !== null && !isNaN(parseFloat(lng))) {
              map.setCenter({
                lat: parseFloat(lat),
                lng: parseFloat(lng)
              })

              setLat(parseFloat(lat))
              setLng(parseFloat(lng))
            }

            const zoom = searchParams.get('zoom')

            if (zoom !== null && !isNaN(parseFloat(zoom))) {
              map.setZoom(parseFloat(zoom))
              
              setZoom(parseFloat(zoom))

              if (parseFloat(zoom) >= 15) {
                loadPlots()
              }
            }

            const input = document.getElementById('search') as HTMLInputElement
            const options = {
              componentRestrictions: { country: 'es' },
              fields: ['geometry', 'name'],
              types: ['(cities)'],
            }

            const autocomplete = new google.maps.places.Autocomplete(input, options)

            autocomplete.addListener('place_changed', () => {
              const place = autocomplete.getPlace()

              if (place.geometry && place.geometry.location) {
                if (place.geometry.viewport) {
                  map.fitBounds(place.geometry.viewport)
                } else {
                  map.setCenter(place.geometry.location)
                  map.setZoom(17)
                }
              }
            })

            map.data.setStyle(feature => ({
              fillColor: 'white',
              strokeColor: 'white',
              fillOpacity: isMapFeatureSelected(feature) ? 0.5 : 0.0,
            }))

            map.data.addListener('click', (event: google.maps.Data.MouseEvent) => {
              if (!isMapFeatureSelected(event.feature)) {
                map.data.overrideStyle(event.feature, {
                  fillColor: 'white',
                  fillOpacity: 0.5,
                })

                setSelectedLands([...selectedLandsRef.current, mapFeatureId(event.feature)])
              } else {
                setSelectedLands(selectedLandsRef.current.filter(id => id !== mapFeatureId(event.feature)))

                map.data.overrideStyle(event.feature, {
                  fillOpacity: 0.3,
                })
              }
            })

            map.data.addListener('mouseover', (event: google.maps.Data.MouseEvent) => {
              if (!isMapFeatureSelected(event.feature)) {
                map.data.overrideStyle(event.feature, {
                  fillOpacity: 0.3,
                })
              }

              const tooltip = document.getElementById('map-tooltip')

              if (tooltip) {
                tooltip.style.display = 'block'

                const landId = ['provincia', 'municipio', 'agregado', 'zona', 'poligono', 'parcela']
                  .map(property => event.feature.getProperty(property))
                  .join(':')

                const areaHa = (event.feature.getProperty('dn_surface') / 10000).toFixed(2)

                tooltip.innerHTML = `${landId} (${areaHa} ha)`
              }
            })

            map.data.addListener('mousemove', (event: google.maps.Data.MouseEvent) => {
              const tooltip = document.getElementById('map-tooltip')

              if (tooltip) {
                const domEvent = event.domEvent as MouseEvent
                const topnavHeight = 65

                tooltip.style.top = ((domEvent.y + 20) + tooltip.clientHeight) <= window.innerHeight
                  ? `${(domEvent.y - topnavHeight + 20)}px`
                  : `${(domEvent.y - topnavHeight - tooltip.clientHeight - 10)}px`

                tooltip.style.left = ((domEvent.x + 20) + tooltip.clientWidth) <= window.innerWidth
                  ? `${(domEvent.x + 20)}px`
                  : `${(domEvent.x - tooltip.clientWidth - 10)}px`
              }
            })

            map.data.addListener('mouseout', (event: google.maps.Data.MouseEvent) => {
              if (!isMapFeatureSelected(event.feature)) {
                map.data.overrideStyle(event.feature, {
                  fillOpacity: 0.0,
                })
              }

              const tooltip = document.getElementById('map-tooltip')

              if (tooltip) {
                tooltip.style.display = 'none'
              }
            })

            map.addListener('center_changed', () => {
              const newLat = map?.getCenter()?.lat()
              const newLng = map?.getCenter()?.lng()

              if (newLat !== undefined && newLng !== undefined) {
                setLat(newLat)
                setLng(newLng)
              }
            })

            map.addListener('idle', () => {
              loadPlots()
            })

            map.addListener('zoom_changed', () => {
              const newZoom = map?.getZoom()

              if (newZoom !== undefined) {
                setZoom(newZoom)

                if (newZoom < 15) {
                  map.data.forEach(feature => map.data.remove(feature))
                }
              }
            })
          }}
        >
        </GoogleMapReact>

        <div
          id="map-tooltip"
          className="px-3 py-2 fs-5 rounded-2 border"
          style={{
            position: 'absolute',
            display: 'none',
            background: 'white',
          }}
        />

        { (busy || error) && (
          <div 
            className="d-flex align-items-center justify-content-center"
            style={{
              position: 'absolute',
              top: '0px',
              bottom: '0px',
              left: '0px',
              right: '0px',
              background: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            { 
              busy 
                ? (
                  <div className="bg-white px-4 py-3 rounded-1">
                    <Spinner
                      animation="border"
                      style={{ width: '16px', height: '16px', opacity: 0.75 }}
                      className="me-3"
                    />
                    Loading...
                  </div>
                ) 
                : (
                  <div className="bg-white p-4 rounded-3">
                    <p className="text-center">
                      An error has occurred. Please try again.
                    </p>

                    <p className="text-center mb-0">
                      <LoadingButton
                        busy={busy}
                        className="btn btn-primary d-flex align-items-center mx-auto"
                        onClick={() => { loadPlots() }}
                        disabled={busy || zoom < 15}
                      >
                        <RefreshCw
                          size="14px"
                          className="me-3"
                        />
                        Load again
                      </LoadingButton>
                    </p>
                  </div>
                )
            }
          </div>
        ) }
      </div>
    </>
  )
}
