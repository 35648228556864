import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { ArrowUpRight } from 'react-feather'

import { DetailData } from '../../../netlify/functions/detail/detail'
import DetailCard from '../DetailCard'

export default function General({ data }: { data: DetailData }) {
  return (
    <>
      <DetailCard
        title="General"
        initialShow={true}
      >
        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Country
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            Spain
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Identifiers
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.general.identifiers.map(identifier => (
              <span key={identifier}>
                { identifier }<br />
              </span>
            )) }
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Province
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.general.provinces.map(province => (
              <span key={province}>
                { province }<br />
              </span>
            )) }
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Municipality
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.general.towns.map(town => (
              <span key={town}>
                { town }<br />
              </span>
            )) }
          </Col>
        </Row>

        <Row className="mb-0">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Cadastral ref.
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.general.cadastral_refs.map((ref, index) => (
              <span key={ref}>
                <a
                  href={data.general.cadastre_links[index]}
                  target="_blank"
                  rel="noreferrer"
                  title="View details in Spanish cadastre"
                >
                  { ref }

                  <ArrowUpRight
                    size="16px"
                    className="ms-1"
                  />
                </a>

                <br />
              </span>
            )) }
          </Col>
        </Row>
      </DetailCard>
    </>
  )
}
