import classNames from 'classnames'
import { ReactNode, useState } from 'react'
import { Card } from 'react-bootstrap'
import { ChevronDown, ChevronUp } from 'react-feather'

export default function DetailCard({ title, children, initialShow = false }: { title: string, children: ReactNode, initialShow?: boolean }) {
  const [show, setShow] = useState<boolean>(true)

  return (
    <Card className="mb-3">
      <Card.Header 
        className={classNames(
          'align-items-center',
          'cursor-pointer',
          !show && 'border-transparent',
        )}
        onClick={() => setShow(!show)}
      >
        <span>
          { title }
        </span>

        { show ? (
          <ChevronDown size="18px" />
        ) : (
          <ChevronUp size="18px" />
        ) }
      </Card.Header>

      { show && (
        <Card.Body>
          { children }
        </Card.Body>
      ) }
    </Card>
  )
}
