import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '../api'

import type { RootState } from './index'

interface MetaState {
  user: { [key: string]: any }|null
}

const initialState: MetaState = {
  user: null
}

export const fetchMeta = createAsyncThunk('meta/fetch', async () => {
  const response = await api.get('/.netlify/functions/meta')
  
  return response.data.data
})

export const metaSlice = createSlice({
  name: 'meta',

  initialState,

  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchMeta.pending, (state, action) => {
        //
      })
      .addCase(fetchMeta.fulfilled, (state, action) => {
        state.user = action.payload.user
      })
      .addCase(fetchMeta.rejected, (state, action) => {
        //
      })
  }
})

export const selectUser = (state: RootState) => state.meta.user

export default metaSlice.reducer
