import { useField } from 'formik'
import { Props as SelectProps } from 'react-select'

import ErrorFeedback from '../errors/ErrorFeedback'
import SelectVendor from '../vendor/Select'

export default function Select({ name, label, options, ...props }: { name: string, label: string } & SelectProps) {
  const [field, meta, helpers] = useField(name)

  const getOptionValue = props.getOptionValue ?? ((option: any) => option.value)

  return (
    <>
      <SelectVendor
        options={options}
        className={meta.error && 'is-invalid'}
        id={name}
        name={name}
        placeholder={label}
        value={options?.find((o: any) => getOptionValue(o) === field.value)}
        onChange={(option: any) => helpers.setValue(getOptionValue(option))}
        {...props}
      />

      <ErrorFeedback error={meta.error} />
    </>
  )
}
