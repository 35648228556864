import { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Info } from 'react-feather'

import { DetailData } from '../../../netlify/functions/detail/detail'
import { currencyFormat } from '../../helpers'
import DetailCard from '../DetailCard'

enum Status {
  None,
  ShowCapModal,
}

export default function Economics({ data }: { data: DetailData }) {
  const [status, setStatus] = useState<Status>(Status.None)

  return (
    <>
      <DetailCard
        title="Economics"
      >
        <Row className="mb-0">
          <Col
            xs={12}
            sm={4}
            className="text-muted d-flex align-items-center"
          >
            <span><abbr title="Common Agricultural Policy">CAP</abbr> payment (est.)</span>
            
            <Info
              size="14px"
              className="cursor-pointer ms-2"
              onClick={() => setStatus(Status.ShowCapModal)}
            />
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { currencyFormat(data.economics.cap_payment, 'EUR', true) } per year (2022)
          </Col>
        </Row>
      </DetailCard>

      <Modal
        show={status === Status.ShowCapModal}
        onHide={() => setStatus(Status.None)}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Estimated payment for Common Agricultural Policy
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            The estimated payment for the Basic Payment Scheme of the Common Agricultural Policy for the selected land is computed as follows:
          </p>

          <Row className="mb-3">
            <Col
              xs={12}
              md={3}
              className="text-muted"
            >
              Subplot
            </Col>

            <Col
              className="text-muted"
            >
              Area
            </Col>
            
            <Col
              className="text-muted"
            >
              Region
            </Col>

            <Col
              className="text-muted"
            >
              €/ha
            </Col>

            <Col
              className="text-muted"
            >
              Total €
            </Col>
          </Row>

          { data.subplots.features
            .filter(subplot => subplot.properties.cap_region)
            .map((subplot, index) => {
              return (
                <Row
                  key={index}
                  className="mb-3"
                >
                  <Col
                    xs={12}
                    md={3}
                  >
                    { subplot.properties.id }
                  </Col>

                  <Col>
                    { subplot.properties.cap_area.toFixed(2) } ha 
                  </Col>

                  <Col>
                    { subplot.properties.cap_region }
                  </Col>

                  <Col>
                    €{ subplot.properties.cap_payment.toFixed(2) } / ha 
                  </Col>

                  <Col>
                    €{ (subplot.properties.cap_area * subplot.properties.cap_payment).toFixed(2) }
                  </Col>
                </Row>
              )
            }) }

          <p className="mt-4">
            The total sum of the amounts above is <b>€{ data.economics.cap_payment.toFixed(2) }</b>.
          </p>

          <p>
            This value is estimated based on the average regional values for 2022 and should be updated with the actual payments received by the current landowner.
          </p>

          <hr />

          <p>
            Information of the regions applicable to each subplot of the selected land is provided by the {' '}
            
            <a
              href={'https://sigpac.mapa.es/fega/visor/'}
              target="_blank"
              rel="noreferrer"
            >
              Geographical Information System of Agricultural Plots (SIGPAC)
            </a>.
          </p>

          <p className="mb-0">
            Average regional values of the CAP BPS for 2022 can be found {' '}

            <a
              href="https://www.fega.gob.es/sites/default/files/VMR_2022_en_el_regimen_de_pago_basico_v1.0.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
