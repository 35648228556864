import { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { Info } from 'react-feather'

import { DetailData } from '../../../netlify/functions/detail/detail'
import DetailCard from '../DetailCard'

enum Status {
  None,
  ShowTypeModal,
}

export default function Soil({ data }: { data: DetailData }) {
  const [status, setStatus] = useState<Status>(Status.None)

  return (
    <>
      <DetailCard
        title="Soil"
      >
        <Row className="mb-0">
          <Col
            xs={12}
            sm={4}
            className="text-muted d-flex align-items-center"
          >
            <span>Soil type</span>
            
            <Info
              size="14px"
              className="cursor-pointer ms-2"
              onClick={() => setStatus(Status.ShowTypeModal)}
            />
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.soil.type?.order } { data.soil.type?.suborder } (code { data.soil.type?.code })
          </Col>
        </Row>
      </DetailCard>

      <Modal
        show={status === Status.ShowTypeModal}
        onHide={() => setStatus(Status.None)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mb-0">
            Soil type
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            The soil classification for the selected land, according to the 2003 version of the <abbr title="United States Department of Agriculture">USDA</abbr> Soil Taxonomy classification, is the following:
          </p>

          { Object.values(data.soil.type).map((value, index) => {
            const key = Object.keys(data.soil.type)[index]

            return (
              <Row
                key={key}
                className="mb-3"
              >
                <Col
                  xs={12}
                  md={4}
                  className="text-muted"
                >
                  { key.charAt(0).toUpperCase() + key.substring(1, ['1', '2'].includes(key[key.length-1]) ? key.length-1 : key.length) + (['1', '2'].includes(key[key.length-1]) ? ` ${key[key.length-1]}` : '') }
                </Col>

                <Col
                  xs={12}
                  md={8}
                >
                  { value ?? '–' }
                </Col>
              </Row>
            )
          }) }

          <hr />

          <p>
            This information is based on the <i>Mapa de Suelos de España 2006</i> provided by the Spanish National Centre of Geographical Information.
          </p>

          <p className="mb-0">
            Raw data and metadata can be downloaded from the Downloads Centre of the Spanish National Centre of Geographical Information {' '}

            <a
              href="http://centrodedescargas.cnig.es/CentroDescargas/busquedaRedirigida.do?ruta=PUBLICACION_CNIG_DATOS_VARIOS/aneTematico/Espana_Suelos_2006_mapa_17073_spa.zip#"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>.
          </p>
        </Modal.Body>
      </Modal>
    </>
  )
}
