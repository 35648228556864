import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import App from './App'
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory'
import { setupAxiosInterceptors } from './utils/api'
import store from './utils/store'
import { setStatusCode } from './utils/store/status'

import './styles/theme.scss'
import 'react-toastify/dist/ReactToastify.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <App />

          <ToastContainer 
            position="bottom-right"
            autoClose={5000}
            closeOnClick={false}
            draggable={false}
          />
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

const { dispatch } = store

setupAxiosInterceptors({
  onForbidden: () => {
    dispatch(setStatusCode(403))
  },

  onNotAdmin: () => {
    dispatch(setStatusCode(401))
  },

  onNotFound: () => {
    dispatch(setStatusCode(404))
  }
})

