import { MutableRefObject, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Eye, EyeOff } from 'react-feather'

import { DetailData } from '../../../netlify/functions/detail/detail'
import DetailCard from '../DetailCard'

enum Status {
  None,
}

export default function Topography({ data, mapRef }: { data: DetailData, mapRef: MutableRefObject<google.maps.Map|null> }) {
  const [status, setStatus] = useState<Status>(Status.None)
  
  const floodRiskLayer = (year: number) => {
    const map = mapRef.current

    if (map === null) {
      console.log('map not ready')
      return
    }
    
    return new google.maps.ImageMapType({
      getTileUrl: (coord, zoom) => {
        if (zoom < 10) {
          return ''
        }
        
        const proj = map.getProjection()
        const zfactor = Math.pow(2, zoom)

        const top = proj?.fromPointToLatLng(new google.maps.Point(coord.x * 256 / zfactor, coord.y * 256 / zfactor))
        const bottom = proj?.fromPointToLatLng(new google.maps.Point((coord.x + 1) * 256 / zfactor, (coord.y + 1) * 256 / zfactor))

        const bbox = `${top?.lng()},${bottom?.lat()},${bottom?.lng()},${top?.lat()}`

        return `https://wms.mapama.gob.es/sig/Agua/ZI_LaminasQ${year}/wms.aspx?FORMAT=image%2Fpng&LAYERS=NZ.RiskZone&TRANSPARENT=true&ANTIALIAS=none&OPACITY=0&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&STYLES=&SRS=EPSG%3A4326&BBOX=${bbox}&WIDTH=256&HEIGHT=256`
      },
      tileSize: new google.maps.Size(256, 256)
    })
  }

  const [floodRiskOverlayShown, setFloodRiskOverlayShown] = useState<boolean>(false)
  const floodRiskOverlayToggle = () => {
    const map = mapRef.current

    if (map === null) {
      return
    }

    if (!floodRiskOverlayShown) {
      map.overlayMapTypes.push(floodRiskLayer(500))
      map.overlayMapTypes.push(floodRiskLayer(100))
      map.overlayMapTypes.push(floodRiskLayer(10))

      setFloodRiskOverlayShown(true)
    } else {
      map.overlayMapTypes.clear()

      setFloodRiskOverlayShown(false)
    }
  }

  return (
    <>
      <DetailCard
        title="Topography"
      >
        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Total area
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.topography.area.toFixed(2) ?? '–' } ha
          </Col>
        </Row>

        <Row className="mb-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Elevation at centre
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.topography.elevation ?? '–' } m
          </Col>
        </Row>

        <Row className="mb-0">
          <Col
            xs={12}
            sm={4}
            className="text-muted d-flex align-items-center"
          >
            <span>Flood risk</span>

            { true && (
              !floodRiskOverlayShown ? (
                <Eye
                  size="14px"
                  className="cursor-pointer ms-3"
                  onClick={floodRiskOverlayToggle}
                />
              ) : (
                <EyeOff
                  size="14px"
                  className="cursor-pointer ms-3"
                  onClick={floodRiskOverlayToggle}
                />
              )
            ) }
          </Col>

          <Col
            xs={12}
            sm={8}
            className="d-flex"
          >
            { (data.topography.flood_risk.high > 0.001) && (
              <>
                High flood risk (10-year return period): { (data.topography.flood_risk.high * 100).toFixed(1) }%<br />
              </>
            ) }

            { (data.topography.flood_risk.medium > 0.001) && (
              <>
                Medium flood risk (100-year return period): { (data.topography.flood_risk.medium * 100).toFixed(1) }%<br />
              </>
            ) }

            { (data.topography.flood_risk.low > 0.001) && (
              <>
                Low flood risk (500-year return period): { (data.topography.flood_risk.low * 100).toFixed(1) }%<br />
              </>
            ) }

            { (Object.values(data.topography.flood_risk).every(val => val <= 0.001)) && 'No flood risk' }
          </Col>
        </Row>
      </DetailCard>
    </>
  )
}
