import { useAuth0 } from '@auth0/auth0-react'
import { ReactNode, useEffect, useState } from 'react'
import { Container, Dropdown, Nav, Navbar } from 'react-bootstrap'
import { User } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'

import { useAppSelector } from '../utils/hooks'
import { selectUser } from '../utils/store/meta'

export default function Topnav({ children = null }: { children?: ReactNode|null }) {
  const location = useLocation()
  const user = useAppSelector(selectUser)

  const { logout } = useAuth0()

  const [scroll, setScroll] = useState<boolean>(false)

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 25
      
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck)
      }
    }

    document.addEventListener('scroll', onScroll)

    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [scroll])

  return (
    <Navbar
      expand="lg"
      className={scroll ? 'scroll-down' : ''}
      collapseOnSelect={true}
      fixed="top"
    >
      <Container
        fluid
        className="px-lg-4"
      >
        {/* <Navbar.Toggle /> */}

        <Link
          to="/"
          className="order-lg-first"
        >
          <img
            src="/images/logo.png"
            srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
            alt="Bageera"
            style={{ height: '36px' }}
          />
        </Link>

        { user !== null && (
          <Dropdown
            align="end"
            className="order-lg-last ms-auto"
          >
            <Dropdown.Toggle
              as="a"
              size="sm"
              role="button"
              className="d-flex flex-row align-items-center nav-link text-primary pe-0"
            >
              <User size="18px" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => logout({ returnTo: window.location.origin })}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) }

        { children && (
          <div className="d-flex flex-row flex-auto ms-5">
            { children }
          </div>
        ) }

        {/* <Navbar.Collapse>
          <Nav>
            <Nav.Item>
              <Link to="/">
                <Nav.Link
                  as="span"
                  active={location.pathname === '/'}
                >
                  Home
                </Nav.Link>
              </Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  )
}
