import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import Loading from '../aux/Loading'

export default function NotAdmin() {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: window.location.origin })
    return
  }, [])

  return <Loading />
}
