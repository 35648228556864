import GoogleMapReact from 'google-map-react'
import { MutableRefObject } from 'react'

import { DetailData } from '../../../netlify/functions/detail/detail'

export default function Map({ data, mapRef }: { data: DetailData, mapRef: MutableRefObject<google.maps.Map|null> }) {
  const coordsToLatLng = (coords: [number, number]) => ({
    lat: coords[1],
    lng: coords[0],
  })

  return (
    <>
      <GoogleMapReact
        bootstrapURLKeys={{ 
          key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? '',
          libraries: ['places']
        }}
        defaultCenter={coordsToLatLng(data.location.center)}
        defaultZoom={10}
        options={{
          mapTypeId: 'hybrid',
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }: { map: google.maps.Map }) => {
          mapRef.current = map

          const bounds = new google.maps.LatLngBounds()

          data.subplots.features.forEach(feature => {
            feature.geometry.coordinates.forEach((coordinateSet: [number, number][]) => {
              coordinateSet.forEach(coordinates => {
                bounds.extend(coordsToLatLng(coordinates))
              })
            })
          })

          map.fitBounds(bounds)
          
          map.data.addGeoJson(data.subplots)

          map.data.setStyle({
            fillColor: 'white',
            fillOpacity: 0.0,
            strokeColor: 'white',
            strokeOpacity: 1.0,
          })

          map.data.addListener('mouseover', (event: google.maps.Data.MouseEvent) => {
            map.data.overrideStyle(event.feature, {
              fillOpacity: 0.3,
            })

            const tooltip = document.getElementById('map-tooltip')

            if (tooltip) {
              tooltip.style.display = 'block'

              let innerHTML = ''

              innerHTML += `<b>${event.feature.getProperty('id')}</b>`
              
              const area = event.feature.getProperty('area')

              innerHTML += `<br/>Area: ${area.toFixed(2)} ha (${(area / data.topography.area * 100).toFixed(2)}%)`
              innerHTML += `<br/>Average slope: ${event.feature.getProperty('avg_slope').toFixed(2)}%`

              if (event.feature.getProperty('cap_region')) {
                innerHTML += `<br/>CAP region: ${event.feature.getProperty('cap_region')}`

                if (event.feature.getProperty('cap_area')) {
                  innerHTML += `<br/>CAP admissible area: ${event.feature.getProperty('cap_area').toFixed(2)} ha`
                }

                innerHTML += `<br/>CAP average payment: €${event.feature.getProperty('cap_payment').toFixed(2)}/ha`
              }

              const use = event.feature.getProperty('use')
              innerHTML += `<br/>Use: ${use.name}`

              if (event.feature.getProperty('irrigation_coefficient')) {
                innerHTML += `<br/>Irrigation coefficient: ${event.feature.getProperty('irrigation_coefficient').toFixed(2)}%`
              }

              // if (event.feature.getProperty('incidences')) {
              //   innerHTML += `<br/>Use: ${event.feature.getProperty('incidences').join(', ')}`
              // }

              tooltip.innerHTML = innerHTML
            }
          })

          map.data.addListener('mousemove', (event: google.maps.Data.MouseEvent) => {
            const tooltip = document.getElementById('map-tooltip')

            if (tooltip) {
              const domEvent = event.domEvent as MouseEvent
              const topnavHeight = 65

              tooltip.style.top = ((domEvent.y + 20) + tooltip.clientHeight) <= window.innerHeight
                ? `${(domEvent.y - topnavHeight + 20)}px`
                : `${(domEvent.y - topnavHeight - tooltip.clientHeight - 10)}px`

              tooltip.style.left = ((domEvent.x + 20) + tooltip.clientWidth) <= window.innerWidth
                ? `${(domEvent.x + 20)}px`
                : `${(domEvent.x - tooltip.clientWidth - 10)}px`
            }
          })

          map.data.addListener('mouseout', (event: google.maps.Data.MouseEvent) => {
            map.data.revertStyle(event.feature)

            const tooltip = document.getElementById('map-tooltip')

            if (tooltip) {
              tooltip.style.display = 'none'
            }
          })
        }}
      />

      <div
        id="map-tooltip"
        className="px-3 py-2 fs-5 rounded-2 border"
        style={{
          position: 'absolute',
          display: 'none',
          background: 'white',
          maxWidth: '250px',
        }}
      />
    </>
  )
}
