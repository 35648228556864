import classNames from 'classnames'
import SelectAlias, { components, ControlProps, CSSObjectWithLabel, MenuProps, OptionProps, Props as SelectProps } from 'react-select'

// Control
export const Control = (props: ControlProps) => {
  const selectProps = props.selectProps
  const selectClassName = selectProps.className

  const isValid = selectClassName && selectClassName.includes('is-valid')
  const isInvalid = selectClassName && selectClassName.includes('is-invalid')

  const classes = classNames(
    props.isMulti ? 'form-control' : 'form-select',
    props.menuIsOpen && 'border-primary',
    isValid && 'is-valid',
    isInvalid && 'is-invalid'
  )

  return (
    <components.Control
      className={classes}
      {...props}
    />
  )
}

// Menu
export const Menu = (props: MenuProps) => {
  return (
    <components.Menu
      className="dropdown-menu"
      {...props}
    />
  )
}

// Option
export const Option = (props: OptionProps) => {
  return (
    <components.Option
      className={classNames(
        'dropdown-item',
        !props.isFocused && 'text-muted'
      )}
      {...props}
    >
      {props.children}
    </components.Option>
  )
}

export const styles = {
  control: () => ({}),
  indicatorsContainer: () => ({}),
  input: (provided: CSSObjectWithLabel) => ({...provided, margin: '0', padding: 0}),
  menu: () => ({}),
  menuList: () => ({ overflowY: 'auto' as 'auto', maxHeight: '200px' }),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
}

export default function Select(props: SelectProps) {
  return (
    <SelectAlias
      classNamePrefix="select"
      components={{ Control, Menu, Option }}
      styles={styles}
      {...props}
    />
  )
}
