import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Chart } from 'react-chartjs-2'
import 'chart.js/auto'

import { DetailData } from '../../../netlify/functions/detail/detail'
import DetailCard from '../DetailCard'

export default function Climate({ data }: { data: DetailData }) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  return (
    <>
      <DetailCard
        title="Climate"
      >
        <p className="mb-3 text-muted">
          Temperature distribution ({ data.climate.period.start } - { data.climate.period.end })
        </p>

        <div className="mb-4">
          <Chart
            type="bar"
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom' as const,
                },
                tooltip: {
                  mode: 'index',
                  callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y} ºC` 
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value, index, ticks) => `${value} ºC`
                  }
                }
              }
            }}
            data={{
              labels: months,
              datasets: [
                {
                  type: 'bar' as const,
                  label: 'Minimum',
                  data: data.climate.temp_min,
                  backgroundColor: '#5B94C4',
                  order: 2,
                },
                {
                  type: 'bar' as const,
                  label: 'Maximum',
                  data: data.climate.temp_max,
                  backgroundColor: '#E07471',
                  order: 3,
                },
                {
                  type: 'line' as const,
                  label: 'Average',
                  data: data.climate.temp_avg,
                  borderColor: '#555555',
                  borderWidth: 2,
                  pointBackgroundColor: '#555555',
                  fill: false,
                  order: 1,
                },
              ],
            }}
          />
        </div>

        <hr className="my-4" />

        <p className="mb-3 text-muted">
          Precipitation distribution ({ data.climate.period.start } - { data.climate.period.end })
        </p>

        <div className="mb-4">
          <Chart
            type="bar"
            options={{
              responsive: true,
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.parsed.y} mm` 
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: (value, index, ticks) => `${value} mm`
                  }
                }
              }
            }}
            data={{
              labels: months,
              datasets: [
                {
                  type: 'bar' as const,
                  label: 'Precipitation',
                  data: data.climate.precipitation,
                  backgroundColor: '#5BC0DE',
                },
              ],
            }}
          />
        </div>

        <hr className="my-4" />

        <Row className="mt-4">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Closest station
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.climate.station.name }

            {' '}

            <span className="text-muted">
              ({ (data.climate.station.distance).toFixed(2) } km away)
            </span>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col
            xs={12}
            sm={4}
            className="text-muted"
          >
            Source
          </Col>

          <Col
            xs={12}
            sm={8}
          >
            { data.climate.station.provider === 'aemet' && (
              'Agencia Estatal de Meteorología'
            ) }

            { data.climate.station.provider === 'ria_andalucia' && (
              'Junta de Andalucía - Red de Información Agroclimática'
            ) }
          </Col>
        </Row>
      </DetailCard>
    </>
  )
}
